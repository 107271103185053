import React from "react";
import styled from "styled-components";

const PrivacyWrapper = styled.section`
    background: #1a1a1a;
    color: #f0f0f0;
    padding: 50px 20px;
`;

const PrivacyContainer = styled.div`
    max-width: 900px;
    margin: 0 auto;
`;

const PrivacyTitle = styled.h1`
    font-size: 32px;
    font-weight: bold;
    color: #ffcc00;
    margin-bottom: 20px;
    text-align: center;
`;

const PrivacyText = styled.p`
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
`;

const PrivacyList = styled.ul`
    margin: 20px 0;
    padding-left: 20px;
`;

const PrivacyListItem = styled.li`
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 10px;
`;

const PrivacyPolicy = () => {
    return (
        <PrivacyWrapper>
            <PrivacyContainer>
                <PrivacyTitle>Politika Ochrany Soukromí</PrivacyTitle>
                <PrivacyText>
                    V projektu **Cz 3** si velmi vážíme ochrany vašich osobních údajů. Tato politika ochrany soukromí vysvětluje, jak shromažďujeme, používáme a chráníme vaše informace při používání našich stránek.
                </PrivacyText>

                <PrivacyTitle>1. Shromažďování Informací</PrivacyTitle>
                <PrivacyText>
                    Shromažďujeme různé osobní informace při používání našich služeb, a to včetně:
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Vaše jméno a příjmení</PrivacyListItem>
                    <PrivacyListItem>Vaše e-mailová adresa</PrivacyListItem>
                    <PrivacyListItem>Vaše telefonní číslo</PrivacyListItem>
                    <PrivacyListItem>Technické informace jako vaše IP adresa a typ používaného zařízení</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>2. Použití Dat</PrivacyTitle>
                <PrivacyText>
                    Vaše data jsou používána k:
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Poskytování a zlepšování našich služeb</PrivacyListItem>
                    <PrivacyListItem>Kontaktní účely v případě potřeby</PrivacyListItem>
                    <PrivacyListItem>Zajištění bezpečnosti a správného fungování stránek</PrivacyListItem>
                    <PrivacyListItem>Odesílání nabídek a aktualizací (s vaším souhlasem)</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>3. Sdílení Dat</PrivacyTitle>
                <PrivacyText>
                    Vaše osobní údaje neprodáváme ani nepronajímáme. Můžeme je však sdílet s důvěryhodnými partnery pouze za účelem:
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Splnění zákonné povinnosti</PrivacyListItem>
                    <PrivacyListItem>Zlepšení našich služeb pomocí analytických nástrojů</PrivacyListItem>
                    <PrivacyListItem>Zajištění bezpečnosti naší platformy</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>4. Bezpečnost Dat</PrivacyTitle>
                <PrivacyText>
                    Zavádíme přísná bezpečnostní opatření k ochraně vašich informací před neoprávněným přístupem, úpravou nebo zveřejněním.
                </PrivacyText>

                <PrivacyTitle>5. Vaše Práva</PrivacyTitle>
                <PrivacyText>
                    Máte právo na:
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Přístup k vašim osobním údajům</PrivacyListItem>
                    <PrivacyListItem>Žádost o opravu nebo odstranění vašich informací</PrivacyListItem>
                    <PrivacyListItem>Odvolání souhlasu kdykoli</PrivacyListItem>
                    <PrivacyListItem>Podání stížnosti u orgánu pro ochranu údajů</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>6. Cookies</PrivacyTitle>
                <PrivacyText>
                    Používáme cookies k vylepšení vaší zkušenosti na našich stránkách. Svá nastavení cookies můžete kdykoli změnit v nastavení vašeho prohlížeče.
                </PrivacyText>

                <PrivacyTitle>7. Změny Politiky</PrivacyTitle>
                <PrivacyText>
                    Vyhrazujeme si právo kdykoli změnit tuto politiku ochrany soukromí. Všechny změny budou zveřejněny na této stránce.
                </PrivacyText>

                <PrivacyTitle>8. Kontakt</PrivacyTitle>
                <PrivacyText>
                    Pro jakékoli dotazy týkající se naší politiky ochrany soukromí nás můžete kontaktovat na:
                </PrivacyText>
                <PrivacyText>
                    📧 E-mail: kontakt@cz3.com
                </PrivacyText>
                <PrivacyText>
                    📍 Adresa: 15 Ulice Spolupráce, 75000 Praha, Česká republika
                </PrivacyText>
            </PrivacyContainer>
        </PrivacyWrapper>
    );
};

export default PrivacyPolicy;