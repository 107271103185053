import React from "react";
import styled from "styled-components";

const CustomFooter = styled.footer`
  background: linear-gradient(90deg, #1a1a1a, #333333);
  color: #e0e0e0;
  padding: 50px 20px 30px;
  box-sizing: border-box;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
`;

const CustomFooterContent = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
`;

const CustomSection = styled.div`
  flex: 1;
  min-width: 200px;
`;

const CustomSectionTitle = styled.h3`
  font-size: 22px;
  margin-bottom: 12px;
  font-weight: 500;
  color: #ffcc00;
`;

const CustomText = styled.p`
  font-size: 14px;
  margin-bottom: 6px;
  line-height: 1.4;
`;

const CustomFooterLink = styled.a`
  color: #e0e0e0;
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin-bottom: 6px;
  transition: color 0.3s;

  &:hover {
    color: #ffcc00;
  }
`;

const CustomFooterBottom = styled.div`
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  padding-top: 15px;
  font-size: 12px;
  margin-top: 20px;
`;

const Footer = () => {
  return (
      <CustomFooter>
        <CustomFooterContent>
          <CustomSection>
            <CustomSectionTitle>Projekt Cz 3</CustomSectionTitle>
            <CustomText>Adresa: 15 Ulice světla, 75000 Praha, Česká republika</CustomText>
            <CustomText>Telefon: +420 123 456 789</CustomText>
            <CustomText>Email: kontakt@projektcz3.cz</CustomText>
          </CustomSection>
          <CustomSection>
            <CustomSectionTitle>Odkazy</CustomSectionTitle>
            <CustomFooterLink href="/" target="_blank" rel="noopener noreferrer">
              Domov
            </CustomFooterLink>
            <CustomFooterLink href="/about" target="_blank" rel="noopener noreferrer">
              O nás
            </CustomFooterLink>
            <CustomFooterLink href="/contact" target="_blank" rel="noopener noreferrer">
              Kontakt
            </CustomFooterLink>
            <CustomFooterLink href="/policy" target="_blank" rel="noopener noreferrer">
              Zásady ochrany osobních údajů
            </CustomFooterLink>
          </CustomSection>
          <CustomSection>
            <CustomSectionTitle>Sledujte nás</CustomSectionTitle>
            <CustomFooterLink
                href="https://www.facebook.com/projektcz3"
                target="_blank"
                rel="noopener noreferrer"
            >
              Facebook
            </CustomFooterLink>
            <CustomFooterLink
                href="https://twitter.com/projektcz3"
                target="_blank"
                rel="noopener noreferrer"
            >
              Twitter
            </CustomFooterLink>
            <CustomFooterLink
                href="https://www.linkedin.com/company/projektcz3"
                target="_blank"
                rel="noopener noreferrer"
            >
              LinkedIn
            </CustomFooterLink>
            <CustomFooterLink
                href="https://www.instagram.com/projektcz3"
                target="_blank"
                rel="noopener noreferrer"
            >
              Instagram
            </CustomFooterLink>
          </CustomSection>
        </CustomFooterContent>
        <CustomFooterBottom>
          &copy; {new Date().getFullYear()} Projekt Cz 3. Všechna práva vyhrazena.
        </CustomFooterBottom>
      </CustomFooter>
  );
};

export default Footer;