import styled from "styled-components";

const StyledEspace = styled.section`
    background: linear-gradient(90deg, #2c2c2c, #1a1a1a);
    padding: 50px 30px;
    color: #e0e0e0;
`;

const EspaceWrapper = styled.div`
    max-width: 900px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
`;

const BoxVidéo = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* aspect ratio 16:9 */
    margin-bottom: 20px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
`;

const CadreStylisé = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`;

const TexteDescription = styled.p`
    font-size: 20px;
    line-height: 1.8;
    color: #e0e0e0;
    margin-top: 20px;
`;

const BoîteFormulaire = styled.div`
    background: rgba(40, 40, 40, 0.9);
    padding: 35px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
`;

const FormulaireInput = styled.form`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const ChampInput = styled.input`
    padding: 15px;
    border: 1px solid #444;
    border-radius: 8px;
    font-size: 18px;
    transition: border-color 0.3s;

    &:focus {
        border-color: #ff4d4d;
        outline: none;
    }
`;

const BoutonSoumettre = styled.button`
    padding: 16px;
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #ff1a1a;
    }
`;

const BoîteAccord = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #e0e0e0;
    margin-top: 15px;
    text-align: left;

    input[type="checkbox"] {
        margin-right: 10px;
    }

    a {
        color: #ff4d4d;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const InfinityComponent = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        fetch("thanks/index.php", {
            method: "POST",
            body: formData,
        }).catch((error) => {
            console.error("Chyba při odesílání formuláře:", error);
        });

        alert("Žádost byla odeslána");
        e.target.reset();
    };

    return (
        <StyledEspace>
            <EspaceWrapper>
                <div>
                    <BoxVidéo>
                        <CadreStylisé
                            src="https://www.youtube.com/embed/LV9DDzOKEI4?si=mZcZykp_me6r9Fjp"
                            title="Video projektu Cz 3"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </BoxVidéo>
                    <TexteDescription>
                        Projekt Cz 3 je komunitou, která spojuje vývojáře se stejnou vášní pro inovace a spolupráci. Naše vize je vytvořit prostor, kde si můžeme navzájem pomáhat, sdílet nápady a společně pracovat na fascinujících projektech. Připojte se k nám a objevte nekonečné možnosti spolupráce a tvoření.
                    </TexteDescription>
                </div>
                <BoîteFormulaire>
                    <h3 style={{ marginBottom: "25px", color: "#e0e0e0" }}>Registrace</h3>
                    <FormulaireInput onSubmit={handleSubmit}>
                        <ChampInput
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Zadejte své jméno"
                            required
                        />
                        <ChampInput
                            type="text"
                            id="surname"
                            name="surname"
                            placeholder="Zadejte své příjmení"
                            required
                        />
                        <ChampInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder="example@mail.com"
                            required
                        />
                        <ChampInput
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="+420 600 000 000"
                            required
                        />
                        <BoîteAccord>
                            <input type="checkbox" id="terms" name="terms" required />
                            <label htmlFor="terms">
                                Souhlasím s{" "}
                                <a href="/terms" rel="noopener noreferrer">
                                    Obchodními podmínkami
                                </a>{" "}
                                a{" "}
                                <a href="/cookies" rel="noopener noreferrer">
                                    Politikou cookies
                                </a>{" "}
                                a{" "}
                                <a href="/policy" rel="noopener noreferrer">
                                    Politikou ochrany osobních údajů
                                </a>
                            </label>
                        </BoîteAccord>
                        <BoutonSoumettre type="submit">Odeslat žádost</BoutonSoumettre>
                    </FormulaireInput>
                </BoîteFormulaire>
            </EspaceWrapper>
        </StyledEspace>
    );
};
export default InfinityComponent;