import styled from "styled-components";

const NewContainer = styled.section`
    background: linear-gradient(135deg, #1a1a1a, #333333);
    padding: 40px 20px;
    color: #f0f0f0;
`;

const NewCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
`;

const NewCard = styled.div`
    flex: 1;
    min-width: 250px;
    max-width: 280px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-6px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.7);
    }
`;

const NewCardImage = styled.img`
    width: 100%;
    height: 180px;
    object-fit: cover;
`;

const NewCardContent = styled.div`
    padding: 15px;
    text-align: center;
`;

const NewCardTitle = styled.h3`
    font-size: 24px;
    margin-bottom: 10px;
    color: #ffcc00;
`;

const NewCardDescription = styled.p`
    font-size: 16px;
    line-height: 1.4;
    color: #cccccc;
`;

const PrivilegesInfinity = () => {
  return (
    <NewContainer>
      <NewCardsContainer>
        <NewCard>
          <NewCardImage src="/reacti3.jpg" alt="Komunitní Iniciativa" />
          <NewCardContent>
            <NewCardTitle>Tvůrčí Myšlenky</NewCardTitle>
            <NewCardDescription>
              Připojte se k nám a podělte se o své inovativní nápady, které obohatí naši komunitu.
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
        <NewCard>
          <NewCardImage src="/reacti2.jpg" alt="Společné Projekty" />
          <NewCardContent>
            <NewCardTitle>Úplná Řešení</NewCardTitle>
            <NewCardDescription>
              Prozkoumejte širokou škálu technologických služeb, které podporují naši spolupráci.
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
        <NewCard>
          <NewCardImage src="/reacti1.jpg" alt="Odborníci na Technologie" />
          <NewCardContent>
            <NewCardTitle>Technologičtí Specialisté</NewCardTitle>
            <NewCardDescription>
              Náš tým odborníků vytváří strategie pro efektivní rozvoj našich projektů.
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
      </NewCardsContainer>
    </NewContainer>
  );
};
export default PrivilegesInfinity;