import styled from "styled-components";

const NouveauWrapper = styled.section`
  width: 100%;
  padding: 50px 15px;
  background: linear-gradient(45deg, #333333, #555555);
  box-sizing: border-box;
`;

const NouveauContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
`;

const NouveauInfo = styled.div`
  flex: 1;
  min-width: 250px;
  text-align: center;
`;

const NouveauImage = styled.img`
    max-width: 100%;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(255, 255, 255, 0.3);
`;

const NouveauText = styled.div`
    flex: 1;
    min-width: 250px;
`;

const NouveauHeader = styled.h2`
    font-size: 32px;
    color: #ffcc00;
    margin-bottom: 15px;
    font-weight: bold;
`;

const NouveauParagraph = styled.p`
    font-size: 16px;
    color: #e0e0e0;
    line-height: 1.6;
    margin-bottom: 15px;
`;

const About = () => {
  return (
    <NouveauWrapper>
      <NouveauContainer>
        <NouveauText>
          <NouveauHeader>O projektu Cz 3</NouveauHeader>
          <NouveauParagraph>
            V projektu Cz 3 se snažíme spojit vývojáře, kteří chtějí společně vytvářet inovativní digitální řešení. Naším cílem je budování komunity, která sdílí znalosti a zkušenosti.
          </NouveauParagraph>
          <NouveauParagraph>
            Připojte se k nám a objevte nové příležitosti v oblasti vývoje. Společně můžeme dosáhnout úspěchu a posunout hranice technologií.
          </NouveauParagraph>
        </NouveauText>
      </NouveauContainer>
    </NouveauWrapper>
  );
};
export default About;