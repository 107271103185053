import styled from "styled-components";

const StyledContainer = styled.section`
    width: 100%;
    padding: 40px 10px;
    background: linear-gradient(135deg, #333333, #444444);
    box-sizing: border-box;
`;

const StyledBlock = styled.div`
    max-width: 700px;
    margin: 30px auto;
    padding: 30px 20px;
    background-color: #555555;
    border-radius: 10px;
    box-shadow: 0 6px 10px rgba(255, 255, 255, 0.1);
`;

const StyledHeading = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 15px;
    color: #ffffff;
    font-weight: bold;
`;

const StyledText = styled.div`
    font-size: 16px;
    line-height: 1.5;
    color: #dddddd;

    p {
        margin-bottom: 15px;
    }

    a {
        color: #ffa500;
        text-decoration: none;
        font-weight: 500;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const CookiePolicy = () => {
  return (
    <StyledContainer>
      <StyledBlock>
        <StyledHeading>Komunita Vývojářů Cz 3!</StyledHeading>
        <StyledText>
          <p>
            V komunitě Cz 3! si ceníme spolupráce a inovací. Připojte se k nám a společně vytvářejme úžasné projekty!
          </p>
          <p>
            Naše platforma nabízí prostor pro sdílení znalostí, výměnu nápadů a rozvoj vašich dovedností. Věříme, že společná práce vede k nejlepším výsledkům.
          </p>
          <p>
            Připojením se k naší komunitě získáte přístup k různým projektům a příležitostem, jak se zapojit a přispět.
          </p>
          <p>
            Pro více informací, neváhejte navštívit naši{" "}
            <a href="/privacy" target="_blank" rel="noopener noreferrer">
              stránku o ochraně osobních údajů
            </a>.
          </p>
        </StyledText>
      </StyledBlock>
    </StyledContainer>
  );
};
export default CookiePolicy;