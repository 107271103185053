import styled from "styled-components";

const NewWrapper = styled.div`
    max-width: 900px;
    margin: 60px auto 30px;
    padding: 30px 20px;
    background-color: #1e1e1e;
    border-radius: 15px;
    box-shadow: 0 8px 12px rgba(255, 255, 255, 0.2);
`;

const NewHeaderText = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #ff4081;
    font-weight: bold;
`;

const NewTextContent = styled.div`
    font-size: 20px;
    line-height: 1.8;
    color: #e0e0e0;

    p {
        margin-bottom: 15px;
    }

    strong {
        color: #ff4081;
    }
`;

const TermsAndConditions = () => {
  return (
    <>
      <NewWrapper>
        <NewHeaderText>Nové podmínky a ujednání</NewHeaderText>
        <NewTextContent>
          <p>
            Vítejte v komunitě Cz 3! Přístupem na naši platformu souhlasíte s těmito podmínkami a ujednáními, které mohou být kdykoli aktualizovány bez předchozího upozornění. Doporučujeme vám je pravidelně kontrolovat, abyste byli informováni.
          </p>
          <p>
            <strong>1. Použití platformy:</strong> Uživatel se zavazuje používat platformu eticky a v souladu s platnými právními předpisy. Je zakázáno používat platformu k činnostem, které by mohly ohrozit bezpečnost nebo integritu informací.
          </p>
          <p>
            <strong>2. Práva na obsah:</strong> Veškerý obsah, včetně textů, obrázků, log a softwaru, je výhradním vlastnictvím komunity Cz 3 a je chráněn zákony o duševním vlastnictví.
          </p>
          <p>
            <strong>3. Odpovědnost:</strong> Komunita Cz 3 nenese odpovědnost za přímé, nepřímé nebo následné škody vyplývající z používání nebo neschopnosti používat platformu.
          </p>
          <p>
            <strong>4. Aktualizace a změny:</strong> Vyhrazujeme si právo kdykoli měnit, aktualizovat nebo odstraňovat jakoukoli část těchto podmínek bez předchozího upozornění. Pokračující používání platformy znamená přijetí těchto změn.
          </p>
          <p>
            <strong>5. Právo a soudní příslušnost:</strong> Tyto podmínky se řídí a vykládají v souladu s českým právem. V případě sporu se obě strany podřídí výhradní soudní příslušnosti soudů v Praze.
          </p>
          <p>
            Pokud máte jakékoli dotazy nebo potřebujete více informací, neváhejte nás kontaktovat.
          </p>
        </NewTextContent>
      </NewWrapper>
    </>
  );
};
export default TermsAndConditions;