import styled from "styled-components";

const StyledLocationWrapper = styled.div`
    width: 100%;
    padding: 40px 20px;
    background-color: #1a1a1a;
    box-sizing: border-box;
`;

const StyledInnerContent = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;

const StyledHeading = styled.h2`
    font-size: 32px;
    color: #e0e0e0;
    margin-bottom: 30px;
    text-align: center;
`;

const StyledDescription = styled.p`
    font-size: 20px;
    color: #b0b0b0;
    line-height: 1.8;
    text-align: center;
    margin-bottom: 30px;
`;

const StyledMapContainer = styled.div`
    width: 100%;
    height: 500px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 8px 12px rgba(255, 255, 255, 0.2);
`;

const BloqueUbicacion = () => {
    return (
        <StyledLocationWrapper>
            <StyledInnerContent>
                <StyledHeading>Připojte se k našemu komunity vývojářů</StyledHeading>
                <StyledDescription>
                    Naše tým se nachází v srdci dynamického prostředí, kde se setkávají nadšení vývojáři. 
                    Přijďte a zažijte spolupráci na projektech, které změní způsob, jakým pracujeme.
                </StyledDescription>
                <StyledMapContainer>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.327993625094!2d-74.00684768403661!3d40.71277599387998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2588f046ee66b%3A0xa9922e5e2d02a9a3!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1scs!2scz!4v1615588055604!5m2!1scs!2scz"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        title="Mapa New Yorku"
                    ></iframe>
                </StyledMapContainer>
            </StyledInnerContent>
        </StyledLocationWrapper>
    );
};
export default BloqueUbicacion;