import React, { useState, useEffect } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1e1e1e;
  color: #f0f0f0;
  padding: 20px 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const StyledText = styled.p`
  margin: 0;
  font-size: 20px;
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 15px;
`;

const StyledButton = styled.button`
  background: #3a3a3a;
  border: none;
  border-radius: 5px;
  color: #f0f0f0;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background: #555;
  }
`;

const CookieBanner = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "přijato");
    setVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "odmítnuto");
    setVisible(false);
  };

  if (!visible) return null;

  return (
      <StyledContainer>
        <StyledText>
          Připojte se k naší komunitě vývojářů na projektu Cz 3! Společně budujeme skvělé projekty.
        </StyledText>
        <StyledButtons>
          <StyledButton onClick={handleAccept}>Přijmout</StyledButton>
          <StyledButton onClick={handleDecline}>Odmítnout</StyledButton>
        </StyledButtons>
      </StyledContainer>
  );
};

export default CookieBanner;