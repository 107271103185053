import React, { useState } from "react";
import styled from "styled-components";

const SekceKomunity = styled.section`
    width: 100%;
    padding: 30px;
    background-color: #1a1a1a;
    box-sizing: border-box;
`;

const KontejnerKomunity = styled.div`
    max-width: 600px;
    margin: 30px auto;
`;

const PrvekKomunity = styled.div`
    border-bottom: 1px solid #444444;
    margin-bottom: 12px;
`;

const NadpisKomunity = styled.h3`
    margin: 0;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333333;
    color: #ffffff;
    border-radius: 6px;
`;

const TextKomunity = styled.div`
    padding: 15px;
    font-size: 16px;
    color: #ffffff;
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    background-color: #2c2c2c;
    border-radius: 0 0 6px 6px;
`;

const FAQ = () => {
  const faqItems = [
    {
      question: "Co je Cz 3 a jak to funguje?",
      answer:
        "Cz 3 je inovativní platforma, která spojuje vývojáře a umožňuje spolupráci na vzrušujících projektech, čímž podporuje kreativitu a technologický pokrok."
    },
    {
      question: "Jaké výhody přináší členství v Cz 3?",
      answer:
        "Členstvím v Cz 3 získáte přístup k pokročilým technologiím, odbornému poradenství a stanete se součástí komunity, která podporuje profesní a osobní růst."
    },
    {
      question: "Jak se mohu zaregistrovat na Cz 3?",
      answer:
        "Proces registrace je jednoduchý: stačí vyplnit formulář na našich webových stránkách a řídit se pokyny pro okamžité aktivování vašeho účtu."
    },
    {
      question: "Jaké typy projektů Cz 3 vyvíjí?",
      answer:
        "Vyvíjíme projekty zaměřené na umělou inteligenci, automatizaci a digitální transformaci, které mají za cíl revolučně změnit fungování firem."
    },
    {
      question: "Potřebuji předchozí zkušenosti k účasti?",
      answer:
        "Žádné předchozí zkušenosti nejsou vyžadovány. Cz 3 je navrženo tak, aby bylo přístupné jak začátečníkům, tak profesionálům, a poskytuje zdroje a podporu na každém kroku."
    },
    {
      question: "Kde mohu získat více informací?",
      answer:
        "Další podrobnosti naleznete na našich webových stránkách nebo kontaktujte náš tým podpory, který vám rád zodpoví všechny vaše dotazy."
    }
  ];

  const [openItems, setOpenItems] = useState(Array(faqItems.length).fill(false));

  const toggleItem = (index) => {
    setOpenItems((prev) => {
      const newOpenItems = [...prev];
      newOpenItems[index] = !newOpenItems[index];
      return newOpenItems;
    });
  };

  return (
    <SekceKomunity>
      <KontejnerKomunity>
        {faqItems.map((item, index) => (
          <PrvekKomunity key={index}>
            <NadpisKomunity onClick={() => toggleItem(index)}>
              {item.question} <span>{openItems[index] ? "–" : "+"}</span>
            </NadpisKomunity>
            <TextKomunity isOpen={openItems[index]}>{item.answer}</TextKomunity>
          </PrvekKomunity>
        ))}
      </KontejnerKomunity>
    </SekceKomunity>
  );
};
export default FAQ;