import React from "react";
import styled from "styled-components";

const StyledProjectContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 50px 15px;
    justify-content: center;
    background: linear-gradient(90deg, #121212, #1e1e1e);
    box-sizing: border-box;
`;

const StyledImageContainer = styled.div`
    flex: 1;
    min-width: 280px;
    max-width: 550px;
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
`;

const StyledImage = styled.img`
    width: 100%;
    height: auto;
    display: block;
`;

const StyledContentContainer = styled.div`
    flex: 1;
    min-width: 280px;
    max-width: 550px;
    background-color: #1c1c1c;
    border-radius: 16px;
    padding: 25px 25px 35px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

const StyledProjectTitle = styled.h2`
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 15px;
    font-weight: bold;
    position: relative;
    z-index: 2;
`;

const StyledProjectDescription = styled.p`
    font-size: 16px;
    color: #e0e0e0;
    line-height: 1.5;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
`;

const Decoration = styled.div`
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    width: 6px;
    height: 100%;
    background-color: #ffffff;
    border-radius: 0 4px 4px 0;
    z-index: 1;
`;

const ProjectInfinityBlock = () => {
  return (
    <StyledProjectContainer>
      <StyledImageContainer>
        <StyledImage src="/infinity.jpg" alt="Projekt Infinity" />
      </StyledImageContainer>
      <StyledContentContainer>
        <Decoration />
        <StyledProjectTitle>Projekt CZ 3: Spojte se s námi</StyledProjectTitle>
        <StyledProjectDescription>
          Připojte se k projektu CZ 3, kde se kreativita a technologie setkávají za účelem vývoje společných projektů.
          Naše komunita vítá všechny vývojáře, kteří chtějí spolupracovat a inovovat.
          Staňte se součástí CZ 3 a přispějte k našim projektům!
        </StyledProjectDescription>
      </StyledContentContainer>
    </StyledProjectContainer>
  );
};

export default ProjectInfinityBlock;