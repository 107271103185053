import CountUp from "react-countup";
import styled from "styled-components";
import { FaUsers, FaCode, FaHandshake } from "react-icons/fa";

const NovaSekce = styled.section`
    background: linear-gradient(90deg, #121212, #1e1e1e);
    padding: 50px 30px;
    color: #ffffff;
`;

const NovaHlava = styled.h2`
    text-align: center;
    font-size: 36px;
    margin-bottom: 50px;
    color: #00bcd4;
    font-weight: bold;
`;

const NovaKontejnerMřížka = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
`;

const NovaKrabice = styled.div`
    background: #424242;
    padding: 40px 30px;
    border-radius: 16px;
    text-align: center;
    box-shadow: 0 8px 12px rgba(255, 255, 255, 0.1);
    transition: transform 0.4s ease, box-shadow 0.4s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
    }
`;

const NovaKontejnerIkona = styled.div`
    font-size: 42px;
    margin-bottom: 15px;
    color: #00bcd4;
`;

const NovaTextovaHodnota = styled.div`
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #00bcd4;
`;

const NovaPopis = styled.div`
    font-size: 20px;
    color: #ffffff;
`;

const ProcVybratNas = () => {
  return (
    <NovaSekce>
      <NovaHlava>Co nás odlišuje?</NovaHlava>
      <NovaKontejnerMřížka>
        <NovaKrabice>
          <NovaKontejnerIkona>
            <FaUsers />
          </NovaKontejnerIkona>
          <NovaTextovaHodnota>
            <CountUp end={56789} duration={3} separator="," />
          </NovaTextovaHodnota>
          <NovaPopis>Aktivní členové komunity</NovaPopis>
        </NovaKrabice>
        <NovaKrabice>
          <NovaKontejnerIkona>
            <FaCode />
          </NovaKontejnerIkona>
          <NovaTextovaHodnota>
            <CountUp end={92} duration={3} separator="," />
          </NovaTextovaHodnota>
          <NovaPopis>Inovativní projekty</NovaPopis>
        </NovaKrabice>
        <NovaKrabice>
          <NovaKontejnerIkona>
            <FaHandshake />
          </NovaKontejnerIkona>
          <NovaTextovaHodnota>
            <CountUp end={9876} duration={3} separator="," />
          </NovaTextovaHodnota>
          <NovaPopis>Spokojené týmy</NovaPopis>
        </NovaKrabice>
      </NovaKontejnerMřížka>
    </NovaSekce>
  );
};
export default ProcVybratNas;